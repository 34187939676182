import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  validate(event) {
    const input = event.target;
    const formField = input.closest('.form-field');
    const error = formField.querySelector('.form-error');
    const pattern = new RegExp('^\\s*[А-Яа-яёЁ]+(?:[\' \-][А-Яа-яёЁ]+)*\\s*$');

    if (pattern.test(input.value)) {
      input.closest('div').classList.remove("invalid");
      error.textContent = ''
    } else {
      input.closest('div').classList.add("invalid");
      error.textContent = 'Доступен ввод только кириллицей'
    }
  }

  validateDate(event) {
    const input = event.target;
    const formField = input.closest('.form-field');
    const error = formField.querySelector('.form-error');
    const pattern = /^\d{2}\.\d{2}\.\d{4}$/;

    if (pattern.test(input.value)) {
      input.closest('div').classList.remove("invalid");
      error.textContent = ''
    } else {
      input.closest('div').classList.add("invalid");
      error.textContent = 'Введите дату в формате дд.мм.гггг'
    }
  }
}
