import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map"]
  static values = {
    latitude: Number,
    longitude: Number,
    baloon: String
  }

  connect() {
    if (typeof ymaps === "undefined") {
      this.loadYandexMap()
    } else {
      this.initMap()
    }
  }

  loadYandexMap() {
    const script = document.createElement("script")
    script.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU"
    script.onload = () => this.initMap()
    document.head.appendChild(script)
  }

  initMap() {
    ymaps.ready(() => {
      const lat = this.latitudeValue || 55.751244
      const lng = this.longitudeValue || 37.618423
      const baloon = this.baloonValue || 'МРТ Экперт'

      this.map = new ymaps.Map(this.mapTarget, {
        center: [lat, lng],
        zoom: 15
      })

      const placemark = new ymaps.Placemark([lat, lng], {
        balloonContent: baloon
      })
      this.map.geoObjects.add(placemark)
    })
  }
}
