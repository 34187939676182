import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static values = { url: String, selected: String }

  connect() {
    const data = this.dataFromOptions()

    this.slim = new SlimSelect({
      select: this.element,
      data: this.placeholder().concat(data),
      settings: {
        showSearch: this.element.querySelectorAll('option').length > 10,
        searchPlaceholder: 'Поиск',
        searchText: 'Нет результатов',
        searchingText: 'Поиск...',
        searchHighlight: false,
        showContent: 'down',
        valuesUseText: false,
        placeholderText: '',
        disabled: data.length == 0
      }
    })

    setTimeout( () => {
      this.element.dispatchEvent(new CustomEvent('slim:added'))
    }, 1)
  }

  placeholder() {
    return [{ placeholder: true, text: '' }]
  }

  dataFromOptions() {
    return this.element.querySelectorAll('optgroup').length > 0
      ? Array.from(this.element.querySelectorAll('optgroup')).map (opt => (
        {
          label: opt.getAttribute('label'),
          options: Array.from(opt.querySelectorAll('option')).map ( option => this.optionHTML(option))
        }
      ))
      : Array.from(this.element.querySelectorAll('option')).map (option => this.optionHTML(option))
  }

  optionHTML(option) {
    const complexParts = option.dataset.complexParts ? JSON.parse(option.dataset.complexParts) : [];
    
    const htmlBase = `
      <div class="flex items-baseline justify-between w-full">
        <span class="main mr-2">${option.textContent}</span>
        <span class="info">${option.dataset.info || ''}</span>
      </div>
    `;
    
    const htmlWithComplexParts = `
      <div class="flex items-baseline justify-between w-full">
        <div class="main mr-2 cursor-pointer" data-controller="dropdown" data-dropdown-opened-class="opened">
          <div class="flex items-start justify-start">
            <div class="mr-2 pt-[2px]">
              <svg width="16" height="14" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.15792 11.3297H11.8366C13.057 11.3297 13.6898 10.7026 13.6898 9.49909V2.61726C13.6898 1.41378 13.057 0.786621 11.8366 0.786621H2.15792C0.9375 0.786621 0.304688 1.40813 0.304688 2.61726V9.49909C0.304688 10.7026 0.9375 11.3297 2.15792 11.3297ZM2.94329 3.89418C2.64383 3.89418 2.38958 3.63993 2.38958 3.34047C2.38958 3.03537 2.64383 2.78111 2.94329 2.78111C3.25405 2.78111 3.497 3.02972 3.497 3.34047C3.497 3.64558 3.25405 3.89418 2.94329 3.89418ZM4.68917 3.72468C4.46882 3.72468 4.29932 3.54953 4.29932 3.34047C4.29932 3.12012 4.47447 2.94496 4.68917 2.94496H11.2377C11.4467 2.94496 11.6275 3.12012 11.6275 3.34047C11.6275 3.54953 11.4524 3.72468 11.2377 3.72468H4.68917ZM2.94329 5.67962C2.64383 5.67962 2.38958 5.42536 2.38958 5.12591C2.38958 4.8208 2.64383 4.56655 2.94329 4.56655C3.25405 4.56655 3.497 4.81515 3.497 5.12591C3.497 5.43101 3.25405 5.67962 2.94329 5.67962ZM4.68917 5.51576C4.47447 5.51576 4.29932 5.33496 4.29932 5.12591C4.29932 4.9112 4.47447 4.7304 4.68917 4.7304H8.3335C8.5482 4.7304 8.72335 4.9112 8.72335 5.12591C8.72335 5.33496 8.5482 5.51576 8.3335 5.51576H4.68917ZM2.94329 7.45375C2.63818 7.45375 2.38958 7.2108 2.38958 6.90004C2.38958 6.60059 2.63818 6.35198 2.94329 6.35198C3.25405 6.35198 3.497 6.59494 3.497 6.90004C3.497 7.2108 3.25405 7.45375 2.94329 7.45375ZM4.68917 7.29555C4.46882 7.29555 4.29932 7.12605 4.29932 6.90004C4.29932 6.68534 4.46882 6.52148 4.68917 6.52148H11.2377C11.4524 6.52148 11.6275 6.68534 11.6275 6.90004C11.6275 7.12605 11.4524 7.29555 11.2377 7.29555H4.68917ZM2.94329 9.32959C2.64383 9.32959 2.38958 9.07533 2.38958 8.77023C2.38958 8.47077 2.64383 8.21652 2.94329 8.21652C3.25405 8.21652 3.497 8.46512 3.497 8.77023C3.497 9.08099 3.25405 9.32959 2.94329 9.32959ZM4.68917 9.16574C4.47447 9.16574 4.29932 8.99058 4.29932 8.77023C4.29932 8.56117 4.46882 8.38602 4.68917 8.38602H8.3335C8.55385 8.38602 8.72335 8.56117 8.72335 8.77023C8.72335 8.99058 8.5482 9.16574 8.3335 9.16574H4.68917Z" fill="#A8D3FF"/>
              </svg>
            </div>
            <div class="mr-2">
              <p class="max-w-xl">${option.textContent}</p>
              <p class="text-xxs text-gray-dark mt-1">Дешевле, чем&nbsp;приобретать все&nbsp;услуги по‑отдельности.<p>
            </div>
            <div data-dropdown-target='button' class='group cursor-pointer'>
              <div data-dropdown-target="button" class="info group self-start mt-[-3px] !important p-2 lg:mb-0 group-hover:text-blue-primary group-[.opened]:-scale-y-100 transition-transform flex-shrink-0">
                <svg class="fill-current" width="12" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.34473 9.65137C8.65234 9.64258 8.91602 9.52832 9.15332 9.29102L15.8418 2.45312C16.0352 2.25098 16.1406 2.01367 16.1406 1.72363C16.1406 1.13477 15.6836 0.677734 15.0947 0.677734C14.8135 0.677734 14.5498 0.791992 14.3477 0.994141L8.34473 7.14648L2.3418 0.99414C2.13965 0.791992 1.88477 0.677734 1.59473 0.677734C1.00586 0.677734 0.548828 1.13476 0.548828 1.72363C0.548828 2.00488 0.654297 2.25098 0.847656 2.45312L7.54492 9.29102C7.77344 9.53711 8.03711 9.65137 8.34473 9.65137Z"></path>
                </svg>
              </div>
            </div>
          </div>
          <ul class="mt-2 hidden whitespace-normal text-xs" data-dropdown-target="body">
            ${complexParts.map(part => `<li class="mt-2 ml-6.5">${part}</li>`).join('')}
          </ul>
          <div class="info block sm:hidden mt-2 ml-6.5">${option.dataset.info || ''}</div>
        </div>
        <div class="info hidden sm:block">${option.dataset.info || ''}</div>
      </div>
    `;
  
    const htmlContent = complexParts.length ? htmlWithComplexParts : htmlBase;
  
    return {
      html: htmlContent,
      text: `${option.textContent} ${option.dataset.info || ''}`,
      value: option.getAttribute('value'),
      data: option.dataset,
      selected: this.selectedValue == option.getAttribute('value'),
    };
  }

  fetch() {
    if (!this.element.value) return

    let url = new URL(this.urlValue)
    url.searchParams.append('obj_id', this.element.value)

    window.showLoader()

    fetch(url.href, {
      headers: { accept: 'text/vnd.turbo-stream.html' }
    }).then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
