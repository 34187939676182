import { Controller } from "@hotwired/stimulus"
import AirDatepicker from 'air-datepicker'
import localeRu from 'air-datepicker/locale/ru'

export default class extends Controller {
  connect() {
    this.datepicker = new AirDatepicker(this.element, {
      minDate: '01.01.1900',
      maxDate: new Date(),
      locale: localeRu,
      dateFormat: "dd.MM.yyyy",
      autoClose: false,
      onSelect: ({ formattedDate }) => {
        this.element.value = formattedDate;
      }
    })
    this.element.addEventListener('input', this.updateCalendarView.bind(this))
  }

  updateCalendarView(event) {
    const inputValue = event.target.value;
    const parsedDate = this.parseDate(inputValue, "dd.MM.yyyy");

    if (parsedDate) {
      this.datepicker.setViewDate(parsedDate);

      if (this.isFullDate(inputValue)) {
        console.log(inputValue)
        console.log(parsedDate)
        // debugger;
        this.datepicker.selectDate(parsedDate);
        this.datepicker.show();
      }
    }
  }

  isFullDate(dateString) {
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
    return datePattern.test(dateString);
  }

  parseDate(dateString, format) {
    const [day, month, year] = dateString.split(".");
    const dayInt = parseInt(day, 10);
    const monthInt = parseInt(month, 10) - 1;
    const yearInt = parseInt(year, 10);
  
    if (dayInt > 0 && monthInt >= 0 && yearInt > 0) {
      return new Date(yearInt, monthInt, dayInt);
    }
  
    const date = new Date();
    if (dayInt > 0) date.setDate(dayInt);
    if (monthInt >= 0) date.setMonth(monthInt);
    if (yearInt > 0) date.setFullYear(yearInt);
  
    return date;
  }
}
